import React from 'react'
import Slider from '@mui/material/Slider'
import BaseQuestion from '../baseQuestion'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'


const MatrixHexSlider = ({ displayComments, disabled, saveResponse, fullResponse, reponse, question, comment, commentUser, showComments, questionHelpText }) => {
  const [sliderColor, setSliderColor] = React.useState({ physical_impact: '', physical_urgence: '', physical_probability: '', financial_impact: '', financial_urgence: '', financial_probability: '' })
  const [options, setOptions] = React.useState([])
  const { t } = useTranslation()
  const [marks, setMarks] = React.useState([])
  const [step, setStep] = React.useState(0)
  const [responseState, setResponseState] = React.useState({
    physical_impact: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 },
    financial_impact: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 },
    physical_urgence: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 },
    physical_probability: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 },
    financial_urgence: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 },
    financial_probability: { value: 0, choiceIndex: 0, option: null, nextOption: null, pourcentage: 0 }
  })

  React.useEffect(() => {
    if (question?.option) {
      setOptions(question.option)
    }
  }, [question])

  React.useEffect(() => {
    if (options.length > 0) {
      const long = options.length
      setStep(100 / (long * 20))
      const list = options.map((op, index) => {
        if (op) {
          const step = (100 - 10) / (long - 1)
          const mark = {
            value: 10 + (index * step),
            label: index + 1,
            text: op.text ? op.text : ''
          }
          return mark
        }
      })
      setMarks(list)
    }
  }, [options])

  const isInitialized = React.useRef(false) // Track if responseState has been initialized

  React.useEffect(() => {
    if (reponse?.physical_impact && !isInitialized.current) {
      setResponseState({
        physical_impact: reponse?.physical_impact || responseState.physical_impact,
        financial_impact: reponse?.financial_impact || responseState.financial_impact,
        physical_urgence: reponse?.physical_urgence || responseState.physical_urgence,
        physical_probability: reponse?.physical_probability || responseState.physical_probability,
        financial_urgence: reponse?.financial_urgence || responseState.financial_urgence,
        financial_probability: reponse?.financial_probability || responseState.financial_probability
      })
      isInitialized.current = true // Mark as initialized
    }
  }, [reponse])

  const debouncedSaveResponse = React.useCallback(
    debounce((state) => {
      saveResponse(state)
    }, 300),
    [saveResponse]
  )
  const handleSliderChange = (e, type) => {
    const value = e.target.value
    const step = (100 - 10) / (options.length - 1)
    const ch = (value - 10 + step) / step
    const Index = Number(ch.toFixed(4).slice(0, -2))
    const choiceIndex = Math.round(ch * 10) / 10
    const pourcentage = Math.floor((Index - Math.floor(Index)) * 100)
    const clearIndex = Math.floor(Index)
    const option = options[clearIndex - 1]?.text || null
    const nOption = options[clearIndex]?.text || null

    setResponseState((prevState) => {
      const updatedState = {
        ...prevState,
        [type]: { value, choiceIndex, option, nextOption: nOption, pourcentage, question: question.uuid }
      }
      debouncedSaveResponse({ // Save the entire response state
        complexValue: updatedState,
        question: question.uuid,
        state: 'draft'
      })
      return updatedState
    })
  }

  const handleSave = (stateText) => {
    const response = {
      complexValue: responseState,
      question: question.uuid,
      state: stateText || 'draft'
    }
    debouncedSaveResponse(response)
  }

  React.useEffect(() => {
    const keysToCheck = ['physical_impact', 'physical_urgence', 'physical_probability', 'financial_impact', 'financial_urgence', 'financial_probability']
    if (responseState) {
      const updatedColors = keysToCheck.reduce((acc, key) => {
        acc[key] = responseState[key]?.choiceIndex >= 1 ? '#3096d1' : '#de6868'
        return acc
      }, {})
      setSliderColor(prev => ({ ...prev, ...updatedColors }))
    }
  }, [responseState])
  return (
    <BaseQuestion
      displayComments={displayComments}
      disabled={disabled}
      sendData={handleSave}
      questionHelpText={questionHelpText}
      reponse={fullResponse}
      showComments={showComments}
      commentUser={commentUser}
      comment={comment}
      question={question}
    >
      <h5 className="text-lg pb-2 px-2">{question.text}</h5>
      <div className='w-full pl-4 flex gap-10'>
        <div className='w-3/4'>
          <div>
            <h5 className="text-lg"> {t('consultant.materiality_matrix.title.physical')}</h5>
            <div className='flex flex-col mt-2'>
              <div className='pl-2'>
                <h5 className="text-md"> {t('consultant.materiality_matrix.labels.impact')}</h5>
                <Slider
                  disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
                  aria-label="Custom marks"
                  value={responseState?.physical_impact?.value}
                  onChange={(e) => handleSliderChange(e, 'physical_impact')}
                  valueLabelFormat={responseState?.physical_impact?.choiceIndex}
                  step={step}
                  marks={marks}
                  sx={{ color: sliderColor.physical_impact, '& .MuiSlider-rail': { color: sliderColor.physical_impact } }}
                />
              </div>
              <div className='pl-4'>
                <h5 className="text-md"> {t('consultant.materiality_matrix.labels.urgence')}</h5>
                <Slider
                  disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
                  aria-label="Custom marks"
                  value={responseState?.physical_urgence?.value}
                  onChange={(e) => handleSliderChange(e, 'physical_urgence')}
                  valueLabelFormat={responseState?.physical_urgence?.choiceIndex}
                  step={step}
                  marks={marks}
                  sx={{ color: sliderColor.physical_urgence, '& .MuiSlider-rail': { color: sliderColor.physical_urgence } }}
                />
              </div>
              <div className='pl-4'>
                <h5 className="text-md"> {t('consultant.materiality_matrix.labels.probability')}</h5>
                <Slider
                  disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
                  aria-label="Custom marks"
                  value={responseState?.physical_probability?.value}
                  onChange={(e) => handleSliderChange(e, 'physical_probability')}
                  valueLabelFormat={responseState?.physical_probability?.choiceIndex}
                  step={step}
                  marks={marks}
                  sx={{ color: sliderColor.physical_probability, '& .MuiSlider-rail': { color: sliderColor.physical_probability } }}
                />
              </div>
            </div>

          </div>
          <div>
            <h5 className="text-lg"> {t('consultant.materiality_matrix.title.finance')}</h5>
            <div className='pl-2 mt-2'>
              <h5 className="text-md"> {t('consultant.materiality_matrix.labels.impact')}</h5>
              <Slider
                disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
                aria-label="Custom marks"
                value={responseState?.financial_impact?.value}
                onChange={(e) => handleSliderChange(e, 'financial_impact')}
                valueLabelFormat={responseState?.financial_impact?.choiceIndex}
                step={step}
                marks={marks}
                sx={{ color: sliderColor.financial_impact, '& .MuiSlider-rail': { color: sliderColor.financial_impact } }}
              />
            </div>

            <div className='pl-4'>
              <h5 className="text-md">{t('consultant.materiality_matrix.labels.urgence')}</h5>
              <Slider
                disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
                aria-label="Custom marks"
                value={responseState?.financial_urgence?.value}
                onChange={(e) => handleSliderChange(e, 'financial_urgence')}
                valueLabelFormat={responseState?.financial_urgence?.choiceIndex}
                step={step}
                marks={marks}
                sx={{ color: sliderColor.financial_urgence, '& .MuiSlider-rail': { color: sliderColor.financial_urgence } }}
              />
            </div>
            <div className='pl-4'>
              <h5 className="text-md">{t('consultant.materiality_matrix.labels.probability')}</h5>
              <Slider
                disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
                aria-label="Custom marks"
                value={responseState?.financial_probability?.value}
                onChange={(e) => handleSliderChange(e, 'financial_probability')}
                onChangeCommitted={handleSave}
                valueLabelFormat={responseState?.financial_probability?.choiceIndex}
                step={step}
                marks={marks}
                sx={{ color: sliderColor.financial_probability, '& .MuiSlider-rail': { color: sliderColor.financial_probability } }}
              />
            </div>
          </div>
          {
          // add material_quantity input here i need to ask yann about it and how they want it
          }
        </div>
        <div className='flex flex-col justify-center'>
          {marks
            ? marks.map((op, index) => (
              op?.label !== 0 && (
                <div className='flex gap-2' key={index}>
                  <span className='px-1 bg-gray-300 rounded-sm max-h-fit'>{op?.label || index + 1}</span>
                  <p className='text-gray-800 text-md'>{op.text || undefined}</p>
                </div>
              )
            ))
            : undefined
          }
          <div />
        </div>
      </div>
    </BaseQuestion>
  )
}

MatrixHexSlider.propTypes = {
  text: PropTypes.string,
  displayComments: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  responses: PropTypes.array,
  commentUser: PropTypes.string,
  saveResponse: PropTypes.func,
  reponse: PropTypes.object,
  question: PropTypes.object.isRequired,
  comment: PropTypes.object,
  questionHelpText: PropTypes.string,
  showComments: PropTypes.bool,
  fullResponse: PropTypes.object
}

export default MatrixHexSlider
