import React from 'react'
import CheckboxQ from './checkbox'
import ProgressBar from './bar/progressBar'
import RadioGroup from './radioGroup'
import BooleanQ from './booleanQ'
import NumberQ from './numberQ'
import MultiSelect from './multiSelect'
import RatingStars from './Rating/RatingStars'
import SimpleSelect from './select/simpleSelect'
import SlideBar from './slideBar/slideBar'
import PropTypes from 'prop-types'
import DoubleSlideBar from './slideBar/doubleSlideBar'
import MatrixQuadSlider from './slideBar/matrixQuadSlider'
import MatrixHexSlider from './slideBar/matrixHexSlider'

function QuestionsControler ({ displayComments, disabled, question, saveResponse, response, comment, commentUser, showComments, questionHelpText }) { 
  if (question) {
    if (question.category === 'radio') {
      return <RadioGroup questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} disabled={disabled} comment={comment} saveResponse={saveResponse} question={question} reponse={response?.value ? response.value : ''} />
    }
    if (question.category === 'checkbox') {
      return <CheckboxQ questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} saveResponse={saveResponse} comment={comment} question={question} reponse={response?.value ? response.value : ''} />
    }
    if (question.category === 'select') {
      return <SimpleSelect questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} disabled={disabled} comment={comment} saveResponse={saveResponse} question={question} reponse={response?.value ? response.value : ''} />
    }
    if (question.category === 'slider') {
      return <SlideBar questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} disabled={disabled} comment={comment} saveResponse={saveResponse} question={question} reponse={response?.complexValue ? response.complexValue : ''} />
    }
    if (question.category === 'matrix') {
      return <DoubleSlideBar questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} disabled={disabled} comment={comment} saveResponse={saveResponse} question={question} reponse={response?.complexValue ? response.complexValue : ''} />
    }
    if (question.category === 'double_slider') {
      return <MatrixHexSlider questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} disabled={disabled} comment={comment} saveResponse={saveResponse} question={question} reponse={response?.complexValue ? response.complexValue : ''} />
    }
    if (question.category === 'multiple') {
      return <MultiSelect questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} disabled={disabled} comment={comment} saveResponse={saveResponse} question={question} reponse={response?.value ? response.value : ''} />
    }
    if (question.category === 'number') {
      return <NumberQ questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} disabled={disabled} comment={comment} saveResponse={saveResponse} question={question} reponse={response?.value ? response.value : ''} />
    }
    if (question.category === 'text') {
      return <NumberQ questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} disabled={disabled} comment={comment} saveResponse={saveResponse} question={question} reponse={response?.value ? response.value : ''} />
    }
    if (question.category === 'rating') {
      return <RatingStars questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} disabled={disabled} comment={comment} saveResponse={saveResponse} question={question} reponse={response?.value ? response.value : ''} />
    }
    if (question.category === 'boolean') {
      return <BooleanQ questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} disabled={disabled} comment={comment} saveResponse={saveResponse} question={question} reponse={response?.value ? response.value : ''} />
    }
    if (question.category === 'progressBar') {
      return <ProgressBar questionHelpText={questionHelpText} fullResponse={response} displayComments={displayComments} showComments= {showComments} commentUser={commentUser} saveResponse={saveResponse} question={question} reponse={response?.value ? response.value : ''} />
    }
  } else { return <></> }
}
QuestionsControler.propTypes = {
  disabled: PropTypes.bool,
  question: PropTypes.object,
  saveResponse: PropTypes.func,
  displayComments: PropTypes.bool,
  response: PropTypes.object,
  comment: PropTypes.object,
  questionHelpText: PropTypes.string,
  commentUser: PropTypes.string,
  showComments: PropTypes.bool
}

export default QuestionsControler
