import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const campaignsAdapter = createEntityAdapter({
})
const initialState = campaignsAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const campaignsApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'campaignsSlice',
  endpoints: builder => ({
    getCampaigns: builder.query({
      query: (id) => `/campaign/entity/${id}`,
      transformResponse: responseData => {
        return campaignsAdapter.setAll(initialState, responseData)
      },
      providesTags: ['Campains']
    }),
    getCampaign: builder.query({
      query: (id) => `/campaign/${id}`,
      transformResponse: responseData => { return responseData }
    }),
    openCamapign: builder.mutation({
      query: ({ id, ...time_deadLine }) => ({
        url: `/campaign/open/${id}`,
        method: 'PATCH',
        body: time_deadLine
      }),
      invalidatesTags: ['Campaigns']
    }),
    changeCamapignddl: builder.mutation({
      query: ({ id, ...time_deadLine }) => ({
        url: `/campaign/modify/deadLine/${id}`,
        method: 'PATCH',
        body: time_deadLine
      }),
      invalidatesTags: ['Campaigns']
    }),
    ChangeCampaignDescription: builder.mutation({
      query: (id, time_deadLine) => ({
        url: `/campaign/open/${id}`,
        method: 'PATCH',
        body: time_deadLine
      }),
      invalidatesTags: ['Campaigns']
    }),
    campaignChangeShowType: builder.mutation({
      query: (data) => ({
        url: `/campaign/showtype/${data.id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Campaigns']
    }),
    addCampaign: builder.mutation({
      query: (campain) => ({
        url: '/campaign/create',
        method: 'POST',
        body: campain
      }),
      invalidatesTags: ['Campaigns']
    }),
    addCampaignMember: builder.mutation({
      query: (data) => ({
        url: '/campaign/addMember',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Campaigns']
    })
  })
})

// getCapaign
export const {
  useGetCampaignsQuery,
  useGetCampaignQuery,
  useAddCampaignMutation,
  useOpenCamapignMutation,
  useAddCampaignMemberMutation,
  useChangeCamapignddlMutation,
  useCampaignChangeShowTypeMutation
} = campaignsApiSlice

export const selectCampaignsResult = campaignsApiSlice.endpoints.getCampaigns.select()

export const selectCampainsData = createSelector(
  selectCampaignsResult,
  campaignsResult => campaignsResult.data
)

export const {
  selectAll: selectAllCampaigns,
  selectById: selectSingleCampaign
} = campaignsAdapter.getSelectors(state => selectCampainsData(state) ?? initialState)
