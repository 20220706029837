import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createDataTable } from '../../../../appComponents/datatable/data-table-factory'
import { useLazyGetMaterialityMatrixExcelQuery } from '../../../../features/materialityMatrix/materialityMatrixSlice'
import { renderHeader } from '../../../../appComponents/datatable/utils/filter'
import { template2 } from '../../../../appComponents/datatable/utils/paginators'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import EvaluateMaterialityMatrix from '../components/evaluateMaterialityMatrix'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import DownloadIcon from '@mui/icons-material/Download'
import { Clock } from '../../../../components/loadData/clockLoader'

function MaterialityMatrixTable ({ materialityMatrixList }) {
  const { t } = useTranslation()
  const { idEntity } = useParams()
  const navigate = useNavigate()
  const [loadingMatrix, setLoadingMatrix] = React.useState({})

  const [triggerGetMaterialityMatrixExcel, { isSuccess, isError, error: errorReport }] =
    useLazyGetMaterialityMatrixExcelQuery()

  const handleDownload = async (id) => {
    setLoadingMatrix((prev) => ({ ...prev, [id]: true }))
    try {
      await handleMatrixExcel(id) // Call the download function
    } finally {
      setLoadingMatrix((prev) => ({ ...prev, [id]: false }))
    }
  }

  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2 justify-center">
        {/* Uncomment if needed */}
        {/* <FolderIcon sx={{ color: '#14b8a6', cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/materiality_matrix/details/${e.id}`)}/> */}

        {!e.evaluated && (
          <EvaluateMaterialityMatrix id={e.id}>
            <CalculateOutlinedIcon sx={{ color: '#db7618', cursor: 'pointer' }} />
          </EvaluateMaterialityMatrix>
        )}
        {e.evaluated && (
          <>
            <QueryStatsIcon
              sx={{ color: '#e0be12', cursor: 'pointer' }}
              onClick={() =>
                navigate(`/hub/entity/${idEntity}/materiality_matrix/evaluation/${e.id}`)
              }
            />
            {loadingMatrix[e.id]
              ? (
              <Clock />)
              : (
              <DownloadIcon
                sx={{ color: '#1b674c', cursor: 'pointer' }}
                onClick={() => handleDownload(e.id)}
              />
                )}
          </>
        )}
      </div>
    )
  }

  const handleMatrixExcel = async (id) => {
    try {
      await triggerGetMaterialityMatrixExcel(id)
    } catch (err) {
      console.error('Error initiating evaluation report generation:', err)
    }
  }

  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const tableConfig = {
    value: materialityMatrixList,
    stateKey: 'dt-state-evaluation-hub-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    filters,
    header,
    globalFilterFields: ['name', 'add_date', 'report_data.evaluationMethod'],
    sortField: 'creation_date',
    sortOrder: -1,
    emptyMessage: t('consultant.evaluation.table.evaluation_methods_listNotFound'),
    columns: [
      { field: 'name', sortable: true, header: t('common.table.name') },
      { field: 'creation_date', sortable: true, header: t('common.table.creation_date') },
      { field: 'intern_campaign_name', sortable: true, header: t('common.table.intern_campaign') },
      { field: 'extern_campaign_name', sortable: true, header: t('common.table.extern_campaign') },
      { body: actionTemplate, header: t('common.table.action') }]
  }

  const DataTableComponent = createDataTable(tableConfig)
  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}
        </div>
    </div>

  )
}
export default MaterialityMatrixTable
