import React from 'react'
import { FilterMatchMode } from 'primereact/api'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createDataTable } from '../../appComponents/datatable/data-table-factory'
import { renderHeader } from '../../appComponents/datatable/utils/filter'
import { template2 } from '../../appComponents/datatable/utils/paginators'

function CompaignsList ({ compaigns }) {
  const { idEntity } = useParams()

  const { t } = useTranslation()
  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const [globalFilterValue, setGlobalFilterValue] = React.useState('')
  const header = renderHeader(globalFilterValue, filters, setFilters, setGlobalFilterValue, t('common.table.search'))

  const navigate = useNavigate()
  const actionTemplate = (e) => {
    return (
      <div className="flex gap-2">
        <RemoveRedEyeIcon sx={{ color: '#1b674c', cursor: 'pointer' }} onClick={() => navigate(`/hub/entity/${idEntity}/campaigns/details/${e.id}`)}/>
      </div>
    )
  }

  const [data, setData] = React.useState()
  React.useEffect(() => {
    if (compaigns) {
      setData(Object.values(compaigns?.entities))
    }
  }, [compaigns])

  const statusTranslate = (e) => t(`consultant.campaign.table.status.${e.status}`)
  const tableConfig = {
    value: data || [],
    stateKey: 'dt-state-compaign-list',
    onFilter: (e) => setFilters(e.filters),
    paginator: true,
    paginatorTemplate: template2,
    dataKey: 'id',
    header,
    filters,
    sortField: 'time_deadLine',
    sortOrder: -1,
    globalFilterFields: ['name', 'time_deadLine', 'status'],
    emptyMessage: t('consultant.campaign.table.campaign_list_notFound'),
    columns: [
      { field: 'name', sortable: true, header: t('common.table.name') },
      { field: 'time_deadLine', sortable: true, header: t('consultant.campaign.table.time_deadline') },
      { field: 'users_count', sortable: true, header: t('consultant.campaign.table.respondants_number') },
      { field: 'status', body: statusTranslate, header: t('common.table.status') },
      { body: actionTemplate, header: t('common.table.action') }]
  }
  const DataTableComponent = createDataTable(tableConfig)

  return (
    <div>
        <div className="card mx-auto w-full border-1 border-gray-300 ">
          {DataTableComponent}
        </div>
    </div>
  )
}
export default CompaignsList
